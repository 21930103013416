@use "./utils.module";

.courseApp {
  h1,
  h2,
  h3,
  h4 {
    color: utils.$bluePurple;
  }

  .columnRight {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  @media only screen and (min-width: utils.$screenXL) {
    h1,
    .h1 {
      font-size: 32px;
      line-height: 42px;
    }
  }
}
