@use "../styles/utils.module";

.featureWrapper {
  background-color: utils.$offWhite;
  width: 100%;
}

.feature {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  padding: 40px 20px;
  min-height: 250px;
  max-width: 1024px;
  margin: 0 auto;
}

.featureReversed {
  @extend .feature;
  background-color: #fafafa;

  .featureRight {
    justify-content: flex-start;
  }
}

.featureLeft {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 150px;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  padding: 15px;
  align-items: center;
}

.featureRight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 300px;
  justify-content: flex-start;
  padding: 15px;
}

.featureImage {
  width: 150px;
  max-width: 100%;
  margin-bottom: 15px;
}

.featureImageLarge {
  width: 300px;
  max-width: 100%;
  margin-bottom: 15px;
}

@media only screen and (min-width: utils.$screenM) {
  .feature {
    flex-direction: row;
    padding: 60px 15px;
    min-height: 360px;

    .featureLeft {
      margin-left: 0px;
      margin-right: 90px;
      width: auto;
      flex-basis: 250px;
    }
  }

  .featureReversed {
    flex-direction: row-reverse;

    .featureLeft {
      margin-left: 90px;
      margin-right: 0px;
    }
  }

  .featureImage {
    max-width: 250px;
  }
}

@media only screen and (min-width: utils.$screenXL) {
  .feature {
    padding: 75px 15px;
  }
}
