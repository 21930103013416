@use "./utils.module";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  line-height: 1.6;
  font-size: 16px;
  color: utils.$black;
  background-color: utils.$white;
}

.container {
  padding-left: 30px;
  padding-right: 30px;

  &.no-padding {
    padding: 0;
  }

  &.no-margin {
    margin: 0;
  }
}

.mainMarginTop {
  margin-top: 90px;

  @media only screen and (min-width: utils.$screenM) {
    margin-top: 60px;
  }
}

* {
  box-sizing: border-box;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;

  &.no-underline {
    text-decoration: none;
  }
}

.input-error {
  color: maroon;
  font-size: 10px;
  margin-top: 5px;
}

img {
  max-width: 100%;
  display: block;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 400;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 700;
}

h1,
.h1 {
  font-size: 26px;
  line-height: 34px;
}

h2,
.h2 {
  font-size: 18px;
  line-height: 21px;
}

h3,
.h3 {
  font-size: 18px;
  line-height: 21px;
}

h4,
.h4 {
  font-size: 18px;
  line-height: 21px;
}

h5,
.h5 {
  font-size: 16px;
  line-height: 20px;
}

input[type="text"] {
  display: block;
  height: 44px;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #714cfd;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 8;
  background-color: #ffffff;
  font-size: 14;
  padding: 15px;
  min-width: 200px;
  max-width: 100%;

  @media only screen and (min-width: utils.$screenL) {
    min-width: 250px;
  }
}

input[type="submit"],
.button {
  color: #212529;
  background-color: utils.$bluePurple;
  color: white;
  border: 1px solid #714cfd;
  padding: 12px 15px;
  border-radius: 0.25rem;
  height: 46px;
  font-weight: 400;
  line-height: 1rem;

  @media only screen and (min-width: utils.$screenM) {
    padding: 12px 20px;
  }

  &.buttonOutlineBlack {
    border: 1px solid utils.$white;
    background-color: #ffffff;
    color: utils.$black;
  }

  &.buttonGreen {
    border: 1px solid utils.$green;
    background-color: utils.$green;
    color: utils.$white;
  }
}

@media only screen and (min-width: utils.$screenM) {
  h1,
  .h1 {
    font-size: 28px;
    line-height: 36px;
  }

  h2,
  .h2 {
    font-size: 24px;
    line-height: 34px;
  }

  h3,
  .h3 {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  h4,
  .h4 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 6px;
  }

  h5,
  .h5 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }
}

@media only screen and (min-width: utils.$screenXL) {
  h1,
  .h1 {
    font-size: 34px;
    line-height: 46px;
  }
}

@media only screen and (max-width: utils.$screenMinDesktop) {
  .desktop-only {
    display: none;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b9b9b9 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  border-radius: 6px;
  border: 2px solid transparent;
}

@media print {
  /* All your print styles go here */
  #header,
  #footer,
  nav.navbar {
    display: none !important;
  }

  html,
  body {
    background-color: white !important;
  }
}

@media only screen and (max-width: utils.$screenMinDesktop) {
  // Mobile style for open navigation bar
  #pippinNavBar {
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    background-color: white;
    padding: 10px 15px;
    z-index: 99;
  }
}

.navbar {
  background-color: white;
  border-bottom: 1px solid utils.$grey;
}
