@use "../../styles/utils.module";

.referralTable {
  @extend .mainAreaPadded;

  overflow-x: scroll;
  width: calc(100% - 20px);

  @media only screen and (min-width: utils.$screenMinDesktop) {
    width: calc(100% - 40px);
  }
}

.answer {
  color: #797979;

  &:first-letter {
    text-transform: uppercase;
  }
}

.categoryWrapper {
  @media print {
    border-bottom: 1px solid #714cfd;
    padding-bottom: 6px;
  }
}

.mainAreaPadded {
  margin-top: 20px;
  margin-left: 10px;

  @media only screen and (min-width: utils.$screenMinDesktop) {
    margin-left: 20px;
  }
}
