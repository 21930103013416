@use "../styles/utils.module";

.parrot {
  position: absolute;
  top: 30px;
  left: calc(50% - 60px);
  width: 120px;
}

@media only screen and (min-width: utils.$screenL) {
  .parrot {
    position: absolute;
    top: 20px;
    left: 30px;
  }
}
