@use "../../styles/utils.module";

.leftCourseBar {
  background-color: utils.$offWhite;
  border-right: 1px solid utils.$offWhite;
  height: calc(100vh - 58px);
  max-height: 100vh;
  overflow-y: scroll;
  padding: 15px 5px 10px 10px;

  .pageTitle {
    font-size: 21px;
  }

  .courseTitle {
    font-size: 14px;
    color: utils.$white;
    background-color: utils.$purple;
    padding: 6px 15px 6px 15px;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .strategyTitle {
    font-size: 12px;
    padding: 6px 0px 6px 0px;
    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.courseContentArea {
  padding: 30px 45px;
  display: flex;
  justify-content: flex-end;
}

.courseContentMeta {
  padding: 30px 45px;
}

.courseSection {
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  position: relative;

  @media only screen and (min-width: utils.$screenL) {
    margin-bottom: 25px;
  }

  .courseSectionStrategy {
    font-size: 14px;
    line-height: 24px;
    padding: 6px 5px 6px 15px;
    border-bottom: 1px solid utils.$offWhite;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    @media only screen and (min-width: utils.$screenL) {
      padding: 8px 15px;
    }
  }
}

.courseVideo {
  width: 100%;
}
