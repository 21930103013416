@use "../styles/utils.module";

@media print {
  @page {
    margin-top: 0.5in;
  }
}

.h1,
.h2,
.h3,
.h4 {
}

reportH1 {
  font-size: 28px;
  line-height: 36px;
}

reportH2 {
  font-size: 21px;
  line-height: 28px;
}

hr {
  border-top: 2px solid #dfd7ff;
  margin: 30px 0;
}

.parrot {
  height: 30px;
  margin-bottom: 45px;
}

a {
  color: #714cfd;
}

.websiteLink {
  font-size: 12px;
  display: none;

  a {
    color: #714cfd;
  }

  @media only screen and (min-width: utils.$screenM) {
    display: block;
  }
}

@media only screen and (min-width: utils.$screenXXL) {
  .landingHeader {
    padding: 0 8%;

    .left {
    }

    .right {
    }
  }
}
