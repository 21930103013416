$primary: #714cfd;
$purple: #714cfd;
$blue: #30c5ff;
$pureWhite: #ffffff;
$white: #fafafa;
$offWhite: #f4f4f4;
$lightGrey: #ececec;
$grey: #b9b9b9;
$darkGrey: #5c5c5c;
$black: #000000;
$green: #06d6a0;

$blueCapri: #30c5ff;
$bluePurple: #714cfd;
$lightGreen: #7cea9c;
$sunglow: #ffcc33;

$screenM: 600px;
// Key breakpoint for Bootstrap ('lg')
$screenMinDesktop: 992px;
$screenL: 1024px;
$screenXL: 1300px;
$screenXXL: 1500px;

:export {
  offWhite: $offWhite;
}
