@use "../../styles/utils.module";

.loginMobileOnly {
  padding: 20px 30px 100px;

  // Once at desktop resolution, hide this!
  @media only screen and (min-width: utils.$screenMinDesktop) {
    display: none !important;
  }

  .pippinParrot {
    width: 150px;
    margin-bottom: 30px;
  }

  a,
  p {
    color: white;
  }

  a {
    text-decoration: underline;
  }

  .marketingText {
    font-size: 14px;
    text-align: center;
  }
}

.loginMarketing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: utils.$bluePurple;
  color: white;
  min-height: 380px;

  @media only screen and (min-width: utils.$screenL) {
    height: 100vh;
  }

  .learningLetters {
    width: 200px;
    max-width: 50%;
    margin-bottom: 30px;
  }

  .marketingText {
    font-size: 18px;
  }
}

.loginArea {
  padding: 40px 45px 0 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 50px;

  @media only screen and (min-width: utils.$screenL) {
    overflow-y: scroll;
    max-height: 96vh;
  }

  @media only screen and (min-width: utils.$screenL) {
    padding: 40px 50px 50px;
  }

  @media only screen and (min-width: utils.$screenXL) {
    padding: 40px 60px 50px;
  }

  .loginForm {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 400px;

    button {
      margin-top: 10px;
    }
  }

  .pippinLogoImg {
    width: 140px;
    margin-bottom: 30px;
  }

  .loginBottom {
    position: absolute;
    bottom: 30px;
    font-size: 14px;
    color: utils.$grey;
  }
}
