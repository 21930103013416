@use "../styles/utils.module";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 40vh;
  padding: 5rem;
  padding-top: 60px;
  background-color: utils.$bluePurple;
  border-top: 1px solid utils.$grey;
  color: utils.$white;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }

  .h4 {
    margin-bottom: 10px;
  }

  a {
    color: utils.$white;
  }
}

.footerSection {
  margin-top: 30px;
}

@media only screen and (min-width: utils.$screenL) {
  .footer {
    flex-direction: row;
    font-size: 14px;
  }

  .footerSection {
    margin-top: 0;
  }
}
